@import '../../styles/index';

.AuthorisedReports {
  h2 {
    margin-top: 2rem;
  }

  h6 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}