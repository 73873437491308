@import '../../styles/index';

.Cities {
  .actions {
    float: right;
    position: relative;
  }

  .addCityButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 10px;
  }

  button, form, label {
    margin-bottom: 1rem;
  }

  .card {
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .cardTitle {
    margin-left: 15px;
    margin-top: 15px;
  }

  .cardHeader {
    margin-left: 15px;
    margin-top: 15px;
  }

  .categoryCheckbox {
    color: $grey;
    margin-top: 10px;
  }

   .cityImage {
    align-self: center;
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .editPictureButtonContainerWithDelete {
    justify-content: center;
    margin-left: 30px;
    margin-top: 20px;
  }

  .editPictureButtonContainer {
    margin-left: 60px;
    margin-top: 20px;
  }

  .editCityButtonsContainer {
    justify-content: center !important;
    margin-top: 10px;
  }

  .editCityImageButtonsContainer {
    justify-content: center;
    margin-top: 10px;
  }

  .editCityModifyImageButton {
    background-color: $white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: $grey-dark !important;
    color: $grey-dark !important;
  }

  .editPictureButtonContainerWithDelete {
    justify-content: center;
    margin-left: 30px;
    margin-top: 20px;
  }

  .editPictureButtonContainer {
    margin-left: 60px;
    margin-top: 20px;
  }

  .editCityCancelButton {
    background-color: $white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: $grey-dark !important;
    color: $grey-dark !important;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 30px;
  }

  .editCityConfirmButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 30px;
  }

  .geographicCityDeleteButton {
    background-color: $red !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 15px;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }
  
  .servicesCheckbox {
    float: left;
    position: relative;
  }
}
