@import '../../styles/index';

.ReportsMessages {
  h2 {
    margin-top: 2rem;
  }

  h6 {
    margin-top: 15px;
  }

  .categoryCheckbox {
    margin-top: 20px;
  }

  .reminder {
    border-radius: 5px;
    color: white;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    text-align: center;
  }

  .reminderHigh {
    background-color: $red;
  }

  .reminderModerate {
    background-color: $orange;
  }

  .reminderLow {
    background-color: $green-light;
  }

  .reportHeader {
    margin-top: 30px;
  }

  .alignCenter {
    text-align: center;
  }

  .sendButton {
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .saveMessagesButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}