@import '../../styles/index';

.AlertDeliveryStatus {
  .smsDeliveryStatusNotAvaialbleLabel {
      color: $grey-dark;
      font-family: Roboto;
      font-weight: 300;
    }
}


