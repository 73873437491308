@import '../../styles/index';

.BrowseProblems {

  .citiesSelectorContainer {
    bottom: 10px;
  }

  div:focus, img:focus {
    outline: none !important;
  }

  .List{
    height: 100%;
    padding-right: 0;
  }

  .Map {
    height: 100%;
    padding-left: 0;
  }

  .row{
    padding-top: 20px;
    height: 700px;
  }

  .card{
    border: none;
  }

  .cardRow{
    height: 80px;
  }

  .card-body{
    background-color: #f7f7f7;
  }

  .selectCategory{
    height: 40px;
    padding: 0;
  }

  .Filters .btn{
    height: 36px !important;
    border-color: #e8e8e8;
    color: #e8e8e8;
    box-shadow: none !important;
  }

  .Filters .btn:hover{
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    outline: none !important;
  }

  .Filters .btn.active{
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    outline: none !important;
  }

  .Filters{
    padding-left: 5px;
    padding-right: 5px;
    flex-basis: 70px;
  }

  .FiltersCategorie{
    padding-right: 0;
  }

  .btn-group{
    float: right;
  }

  .btn{
    color: #aaa !important;
    background-color: $white;
  }

  .itemDescription .col p {
    font-size: 0.75em;
    padding: 0;
    height: 100%;
    word-wrap: break-word;
  }

  @media screen and (max-width: 420px){
    .itemDescription .col p {
      font-size: 0.7em;
    }
  }

  @media screen and (max-width: 320px){
    .itemDescription .col p {
      font-size: 0.5em;
    }
  }

  .itemTitle .col p {
    font-size: 1.0em;
    padding: 0;
    height: 100%;
    word-wrap: break-word;
  }

  .itemTitle .col p span {
    font-size: 0.8em;
    padding: 0;
    height: 100%;
    word-wrap: break-word;
  }

  @media screen and (max-width: 1100px){
    .itemTitle .col p {
      font-size: 0.9em;
    }
  }

  @media screen and (max-width: 1000px){
    .itemTitle .col p {
      font-size: 0.8em;
    }
  }

  @media screen and (max-width: 320px){
    .itemTitle .col p {
      font-size: 0.5em;
    }
  }

  .heatMapButton{
    background-color: #fff;
    border-color: #e8e8e8;
    box-shadow: none !important;
    color: #999 !important;
    height: 36px;
    outline: none !important;
    width: 150px;
  }

  .heatMapButton.active{
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    color: #999 !important;
    outline: none !important;
  }

  .heatMapButton:hover{
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    color: #999 !important;
    outline: none !important;
  }

  .slider > div{
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }

  .Select-menu-outer{
    max-height: 500px;
  }

  .Select-value{
    background-color: #f7f7f7 !important;
    margin-top: 4px;
  }

  .vl {
      border-left: 1px solid #e8e8e8;
      height: 35px;
      position: absolute;
      margin-left: -9px;
      top: 0;
  }

}
