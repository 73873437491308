$white: #fff;
$off-white: #f3f3f3;
$black: #000;
$blue: #223A5E;
$purple: #6676f7;
$logo_color: #fff;

// Greys
$grey-dark: #4f4f4f;
$grey: #949292;
$grey-light: #f7f7f7;
$grey-lightest: #fff;

$red: #EE5F5F;
$orange: #F5BE41;
$green: #0da358;
$green-light: #03bd85;

// Set colors
$primary-color: $blue;
$border-color: $grey-lightest;

// Animation
$animation-time: 0.4s;
$animation-curve: cubic-bezier(0.645, 0.045, 0.355, 1);