@import '../../styles/index';

.Users {
  .activeUserContainer {
    margin-bottom: 50px;
  }

  .addUserButton {
    border-radius: 35px;
    background-color: $green;
    color: $white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  h2 {
    margin-top: 2rem;
  }

  h3 {
    margin-top: 30px;
  }

  .multiSelectUser {
    z-index: 11 !important;
  }
}

  //Edit user
  .editUserButtonsContainer {
    justify-content: center !important;
  }

  .editUserCancelButton {
    background-color: $white !important;
    border-radius: 35px !important;
    border-width: 2px !important;
    border-color: $grey-dark !important;
    color: $grey-dark !important;
  }

  .editUserConfirmButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-left: 10px !important;
  }

  .editUserFieldContainer {
    margin-top: 10px;
  }

  .editUserTitle {
    align-self: center;
    color: $purple;
    font-family: Montserrat;
    font-weight: 700;
    margin-top: 10px;
  }
