@import '../styles/index';

.Header {
  text-align: center;
}

.Footer {
  text-align: center;
  padding: 10px;
  background-color: $blue;
}

.Footer p,
.Footer a {
  margin-bottom: 0rem;
  color: $white;
}

.avatar {
  height: 35px;
  width: 35px;
}

.buttonDropdown {
  margin-right: 10px;
  margin-top: 5px;
}

.cityName h3 {
  color: $white !important;
  margin-top: 10px;
}

.languageSelector {
  color: $white !important;
  margin-top: 10px;
  cursor: pointer;
}

.logout {
  cursor: pointer;
}

.profil {
  margin-right: 10px;
}

.singleNavItem {
  margin-right: 10px;
  margin-top: 5px;
}