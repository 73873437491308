@import '../../styles/index';

.SubscriptionForm {
  .submitButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
  }

  .field {
    margin-bottom: 10px;
  }

  .fieldContainer {
    margin-left: 15px;
  }

  .serviceChoicesContainer {
    margin-left: 15px;
  }

  .title {
    color: $grey-dark;
    font-family: Roboto;
    font-weight: 500;
    left: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-top: 25px;
  }
}


