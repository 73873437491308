@import '../../styles/index';

.Password {
  h2 {
    margin-top: 2rem;
  }

  .modifyPasswordButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 10px;
  }
}
