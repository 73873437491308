@import '../../styles/index';

.Problems {

  h2 {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .demo-loading {
    position: absolute;
    bottom: -40px;
    left: 50%;
  }

  .errorMessage{
    text-align: center;
    padding-top: 100px;
  }

  .itemList {
    border-color:#e8e8e8;
    border-style: solid;
    border-width: 0px 0px 2px 0px;
    padding: 8px 10px;
  }

  .itemList:hover{
    background-color: #e8e8e8;
  }

  .listContainer {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 0;
    background-color: #fff;
  }
}
