@import '../../styles/index';

.Alert {
  .addImageLabel {
    width: 100%;
  }

  .alertImage {
    max-height: 250px;
    object-fit: cover;
    max-width: 250px;
  }

  .alertsSearchTable {
    margin-top: 30px;
  }

  .alertSent {
    background-color: $green;
    border-radius: 20px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }

  .alertUnsent {
    background-color: $purple;
    border-radius: 20px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }

  .categoryCheckbox {
    color: $grey-dark;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-top: 10px;
  }

  .communicationChannelsContainer {
    margin-bottom: 20px;
  }

  .communicationChannelsLabel {
    color: $grey-dark;
    font-family: Roboto;
    font-weight: 300;
  }

  .createAlertImagePreview {
    height: 300px;
    object-fit: contain;
    width: 300px;
  }

  .deletePictureButtonContainer {
    margin-left: 10px;
    margin-top: 15px;
  }

  .editPictureButtonContainerWithDelete {
    margin-left: 30px;
    margin-top: 20px;
  }

  .editPictureButtonContainer {
    margin-left: 60px;
    margin-top: 20px;
  }

  h2 {
    margin-top: 1em;
  }

  .multiSelectCity {
    z-index: 12 !important;
  }

  .multiSelectGroup {
    z-index: 11 !important;
  }

  .searchAlertsTableContainer {
    margin-bottom: 10px;
  }

  .secondaryButton {
    background-color: $white;
    border-radius: 35px;
    border-width: 2px;
    border-color: $grey-dark;
    color: $grey-dark;
  }

  .sendAlertButton {
    border-radius: 35px;
    background-color: $green;
    color: $white;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-top: 25px;

    &:hover {
      background-color: $green;
    }
  }

  .smsCounter {
    color: $grey;
    font-family: Roboto;
    font-weight: 300;
    margin-bottom: 0px;
    margin-left: 30px;
  }
}


// Alert scheduling
.alertSchedulingTitle {
  align-self: center;
  color: $purple;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 10px;
}

.alertSchedulingCancelButton {
  background-color: $white !important;
  border-radius: 35px !important;
  border-width: 2px !important;
  border-color: $grey-dark !important;
  color: $grey-dark !important;
  margin-left: -15px !important;
}

.alertSchedulingConfirmButton {
  background-color: $green !important;
  border-radius: 35px !important;
  color: $white !important;
}

.alertSchedulingConfirmButtonDisabled {
  border-radius: 35px !important;
}

.alertSchedulingRecurrentSelector {
  min-width: 100% !important;
}

.alertSchedulingRecurrentSelectorContainer {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 200px;
}

//Alert targeting
.alertTargetingAddZoneButton {
  background-color: $blue !important;
  border-radius: 35px !important;
  color: $white !important;
}

.alertTargetingButtonsContainer {
  justify-content: center;
  margin-top: 15px;
}

.alertTargetingDeleteZoneButton {
  background-color: $red !important;
  border-radius: 35px !important;
  color: $white !important;
}

.editAlertTargetingButtonsContainer {
  justify-content: center !important;
  margin-top: 30px;
}


// Consult Alert
.consultAlertTitle {
  align-self: center;
  color: $purple;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 10px;
}

.consultAlertCloseButton {
  background-color: $green !important;
  border-radius: 35px !important;
  color: $white !important;
}


// Edit Alert
.communicationChannelsTitleContainer {
  margin-bottom: 20px;
  margin-top: 20px;
}

.editAlertButtonsContainer {
  justify-content: center !important;
}

.editAlertCancelButton {
  background-color: $white !important;
  border-radius: 35px !important;
  border-width: 2px !important;
  border-color: $grey-dark !important;
  color: $grey-dark !important;
}

.editAlertConfirmButton {
  background-color: $green !important;
  border-radius: 35px !important;
  color: $white !important;
  margin-left: 10px !important;
}

.editAlertConfirmButtonDisabled {
  border-radius: 35px !important;
  margin-left: 10px !important;
}

.editAlertDateTimeContainer {
  margin-top: 10px;
}

.editAlertFieldContainer {
  margin-top: 10px;
}

.editAlertImageContainer {
  text-align: center;
}

.editAlertImageButtonsContainer {
  justify-content: center;
  margin-top: 10px;
}

.editAlertModifyImageButton {
  background-color: $white !important;
  border-radius: 35px !important;
  border-width: 2px !important;
  border-color: $grey-dark !important;
  color: $grey-dark !important;
}

.editAlertRecurrentTitleContainer {
  margin-top: 20px;
}

.editAlertTitle {
  align-self: center;
  color: $purple;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 10px;
}

.imagePreview {
  height: auto;
  object-fit: contain;
  width: 100%;
}