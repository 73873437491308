@import '../../styles/index';

.Parking {
  .additionalInfo {
    margin-top: 10px;
  }

  .addZoneButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
  }

  .deleteZoneButton {
    background-color: $red !important;
    border-radius: 35px !important;
    color: $white !important;
    bottom: 25px;
  }

  .editParkingInformationsButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 20px;
  }

   h2 {
    margin-top: 2rem;
  }

  h4 {
    margin-top: 50px;
  }

  h5, h6 {
    margin-top: 20px;
  }

  .marginTop {
    margin-top: 30px;
  }

  .nightParkingZoneArchive {
    margin-bottom: 50px;
  }

  .saveZoneStatusChangesButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 20px;
  }
}


// Log Modal
.log {
  white-space: pre-wrap;
}