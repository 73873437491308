@import '../../styles/index';

.Survey {
  .addChoiceButton {
    background-color: $white;
    border-radius: 35px;
    border-width: 2px;
    border-color: $grey-dark;
    color: $grey-dark;
    margin-top: 10px;
  }

  h2 {
    margin-top: 1em;
  }

  h3 {
    margin-top: 30px;
  }

  .marginBottom {
    margin-bottom: 10px;
  }

  .marginTop {
    margin-top: 20px;
  }

  .multiSelectCity {
    z-index: 12 !important;
  }

  .sendSurveyButton {
    border-radius: 35px;
    background-color: $green;
    color: $white;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-top: 10px;

    &:hover {
      background-color: $green;
    }
  }
}


// Consult Survey
.consultSurveyTitle {
  align-self: center;
  color: $purple;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 10px;
}

.consultSurveyCloseButton {
  background-color: $white !important;
  border-radius: 35px !important;
  border-width: 2px !important;
  border-color: $grey-dark !important;
  color: $grey-dark !important;
}


// Edit Survey
.editSurveyButtonsContainer {
  justify-content: center !important;
}

.editSurveyCancelButton {
  background-color: $white !important;
  border-radius: 35px !important;
  border-width: 2px !important;
  border-color: $grey-dark !important;
  color: $grey-dark !important;
}

.editSurveyConfirmButton {
  background-color: $green !important;
  border-radius: 35px !important;
  color: $white !important;
  margin-left: 10px !important;
}

.editSurveyStatusButton {
  background-color: $grey !important;
  border-radius: 35px !important;
  color: $white !important;
}

.editSurveyStatusContainer{
  justify-content: center;
}

.editSurveyTitle {
  align-self: center;
  color: $purple;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 10px;
}
