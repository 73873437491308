@import '../../styles/index';

.Login {
  flex-grow: 1;

  .connectButton {
    border-radius: 35px;
    background-color: $purple;
    color: $white;
    width: 50%;

    &:hover {
      background-color: $purple;
    }
  }

  .errorMessage {
    color: $red;
    font-weight: 600;
    text-align: center;
  }

  .field {
    margin-bottom: 10px;
  }

  .fieldContainer {
    margin-top: 10px;
  }

  .formContainer {
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: $white;
    display: flex;
  }

  .forgotPassword {
    color: $blue;
    font-weight: 600;
  }

  .forgotPasswordContainer {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .image {
    background-image: url('../../images/city.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .loginForm {
    margin-top: 5px;
    width: 75%;
  }

  .logoImage {
    height: 200px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .logoImageContainer {
    align-items: center;
    justify-content: center;
  }

  .paper {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}