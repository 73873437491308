@import '../../styles/index';

.AlertDetails {
  .alertImage {
    height: auto;
    width: 100%;
  }

  .alertSent {
    background-color: $green-light;
    border-radius: 20px;
    color: $white;
    padding-left: 10px;
    padding-right: 10px;
  }

  .alertStatusContainer {
    justify-content: center;
    margin-top: 10px;
  }

  .alertUnsent {
    background-color: $purple;
    border-radius: 20px;
    color: $white;
    padding-left: 10px;
    padding-right: 10px;
  }

  .imageContainer {
    flex: 1;
    text-align: center;
  }

  .label {
    color: $grey-dark;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
  }

  .title {
    color: $grey-dark;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    padding-right: 5px;
  }
}


