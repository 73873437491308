@import '../../styles/index';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.1);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}