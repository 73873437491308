@import '../../styles/index';

.SurveyDetails {
  .label {
    color: $grey-dark;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
  }

  .title {
    color: $grey-dark;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    padding-right: 5px;
  }
}
