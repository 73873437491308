@import '../../styles/index';

.Redirections {
  h2 {
    margin-top: 2rem;
  }

  .facebookButton {
    background-color: #4267B2 !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 10px;
  }

  .facebookredirectionLabel {
    color: $grey-dark;
    font-family: Roboto;
    font-weight: 300;
  }

  .facebookredirectionLabelContainer {
    margin-top: 20px;
  }

  .modifyEmailButton {
    background-color: $green !important;
    border-radius: 35px !important;
    color: $white !important;
    margin-top: 10px;
  }
}
